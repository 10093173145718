.HomeWrapper{
    position: relative;
    height: 100vh;
}

.HomeBox{
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.HomeBox img{
    width: 30%;
}

.HomeText h1, .HomeText h5{
    margin-bottom: 0;
    margin-top: 0;
    text-align: left;
}

.HomeBox img, .HomeText h1{
    opacity: 0;
    animation: FadeInAnimation;
    animation-duration: 2.75s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}
.HomeText h5{
    opacity: 0;
    animation: FadeInAnimation;
    animation-delay: 2.75s;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}


@keyframes FadeInAnimation{
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

@media(max-width: 1024px){
    .HomeBox{
        flex-direction: column;
        width: 80%;
    }
    .HomeText h1, .HomeText h5{
        text-align: center;
    }
    .HomeBox img{
        width: 40%;
    }
}