.NavWrapper{
    width: 100%;
    position: absolute;
    top: 0;
    opacity: 1;
}

.NavBar{
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.MobileNavBar{
    display: none;
}
.NavLeft{
    width: 40%;
    display: flex;
    justify-content: space-between;
    z-index: 1;
    margin-top: 1%;
}


.NavLeft button, .NavRight a{
    color: white;
    background: none;
    font-size: 1.2rem;
    border: 0;
    font-weight: 300;
    letter-spacing: 0.05rem;
    transition-duration: 0.5s;
}

.NavLeft a{
    text-decoration: none;
    transition-duration: 0.5s;
}


.NavLeft button:hover, .NavLeft a:hover, .NavRight a:hover{
    cursor: pointer;
    color: rgb(175, 175, 175);
    text-decoration: underline;
    transition-duration: 0.5s;
}

.NavRight{
    width: 10%;
    display: flex;
    justify-content: space-between;
    margin-top: 0.5%;
    z-index: 1;
}

.NavRight a{
    color: white;
    font-size: 2rem;
}


#ABOUT{
    animation: FadeInAnimation;
    animation-duration: 1s;
    animation-iteration-count: 1;
}
#SKILLS{
    opacity: 0;
    animation: FadeInAnimation;
    animation-delay: 0.25s;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}
#PROJECTS{
    opacity: 0;
    animation: FadeInAnimation;
    animation-delay: 0.5s;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}
#RESUME{
    opacity: 0;
    animation: FadeInAnimation;
    animation-delay: 0.75s;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}
#GITHUB{
    opacity: 0;
    animation: FadeInAnimation;
    animation-delay: 1s;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}
#LINKEDIN{
    opacity: 0;
    animation: FadeInAnimation;
    animation-delay: 1.25s;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}
#EMAIL{
    opacity: 0;
    animation: FadeInAnimation;
    animation-delay: 1.5s;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}
.hidden{
    display: none;
}

@keyframes FadeInAnimation{
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

@media(max-width: 1024px){
    .NavBar{
        flex-direction: column;
        width: 100%;
    }
    .NavLeft a, .NavLeft button{
        font-size: 1.5rem;
    }
    .NavLeft{
        width: 80%;
        margin-bottom: 2%;
        margin: 2% auto;
    }
    .NavRight{
        width: 10%;
        flex-direction: column;
        height: 12.5vh;
    }
}
@media(max-width: 600px){
    .NavBar{
        display: none;
    }
    .MobileNavBar{
        display: block;
        text-align: left;
        position: relative;
        z-index: 2;
        padding: 1% 2% 2% 1%;
        background:rgba(175, 175, 175, 0.3);
        border-bottom: 2px solid white;
        height: 5.5vh;
    }
    .MobileNavBar svg{
        color: white;
        font-size: 2.5rem;
        width: fit-content;
    }
    .MobileNavLinks{
        position: absolute;
        height: 100vh;
        transform: translateY(-50%);
        width: 100%;
        animation: growDown 0.5s ease-in-out forwards;
        transform-origin: top center;
        padding-bottom: 2%;
        z-index: 1;
        background:rgb(44, 44, 44);
        border-bottom: 5px solid black;
    }
    #MobileAbout{
        margin-top: 5%;;
    }
    .MobileNavLinks p{
        border-top: 1px solid white;
        border-bottom: 1px solid white;
        margin-top: auto;
        padding: 11%;
    }
    #MobileResume{
        border-top: 1px solid white;
        border-bottom: 1px solid white;
        margin-top: auto;
        padding: 11%;
        display: block;
        text-decoration: none;
        margin-bottom: 5%;
    }
    .MobileNavLinks hr{
        width: 100%;
    }
    .MobileContact{
        font-size: 2.2rem;
    }
    .MobileContact a{
        margin: 2%;
    }
}