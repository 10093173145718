body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5, h6, p, a, span, input{
  color: white;
}

h1{
  font-size: 3.3rem;
}
h2{
  font-size: 2.9rem;
}
h3{
  font-size: 2.5rem;
}
h4{
  font-size: 2.1rem;
}
h5{
  font-size: 1.7rem;
}
h6{
  font-size: 1.3rem;
}