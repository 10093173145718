.SkillsWrapper{
    margin: 0 auto;
    text-align: left;
    line-height: 2rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.SkillsWrapper hr{
    width: 20%;
}

.SkillsWrapper p, .SkillsWrapper h6{
    color: black;
}

.SkillsWrapper h6{
    margin-bottom: 0;
    text-align: center;
}
.SkillsWrapper p{
    margin-top: 0;
}

.FrontEndSkills, .BackEndSkills, .SoftSkills{
    width: 100%;
    margin-top: 0;
    padding-bottom: 3%;
    transition-duration: 0.5s;
}

.FrontEndSkills hr, .BackEndSkills hr, .SoftSkills hr{
    width: 10%;
    border-top: 1px solid black;
}

.Skills{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 55%;
    margin: 0 auto;
}

.Skills p{
    display: block;
    border: 1px solid black;
    padding: 0.75% 1.5%;
    margin: 1%;
    transition-duration: 0.5s;
}
.Skills p:hover{
    background: rgb(247,247,247);
}
.SoftSkills{
    margin-bottom: 2%;
}

.BackEndSkills{
    background: rgb(247,247,247);
}

@media(max-width: 1024px){
    .Skills{
        width: 80%;
    }
}

@media(max-width: 600px){
    .Skills{
        width: 90%;
    }
}